import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCheckoutPaymentMethodFormComponent } from './custom-checkout-payment-method-form.component';
import { RouterModule } from '@angular/router';
import { CartNotEmptyGuard, CheckoutAuthGuard, CheckoutPaymentFormModule } from '@spartacus/checkout/base/components';
import {
  CardModule,
  FormErrorsModule,
  IconModule,
  NgSelectA11yModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { CmsConfig, FeaturesConfigModule, I18nModule, provideConfig } from '@spartacus/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [CustomCheckoutPaymentMethodFormComponent],
  imports: [
    CommonModule,
    RouterModule,
    CheckoutPaymentFormModule,
    CardModule,
    SpinnerModule,
    I18nModule,
    FeaturesConfigModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectA11yModule,
    NgSelectModule,
    IconModule,
    FormErrorsModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPaymentFormModule: {
          component: CustomCheckoutPaymentMethodFormComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
  exports: [CustomCheckoutPaymentMethodFormComponent],
})
export class CustomCheckoutPaymentMethodFormModule {}
