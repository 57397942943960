import { Injectable } from "@angular/core";
import { UserIdService, UserPaymentConnector } from "@spartacus/core";
import { BehaviorSubject, Subscription, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ANONYMOUS } from "src/app/tools/utils";

@Injectable({ providedIn: 'root' })
export class DpPaymentMethodService {
    paymentMethods$ = new BehaviorSubject([]);

    constructor(
      private userIdService: UserIdService,
      private userPaymentConnector: UserPaymentConnector
    ) { }

    loadPaymentMethods () {
      const subscription = new Subscription();

      subscription.add(
        this.userIdService.takeUserId().pipe(
          switchMap(userID => {
            if (userID === ANONYMOUS) {
              return of([]);
            }

            return this.userPaymentConnector.getAll(userID);
          })
        ).subscribe(methods => {
          this.paymentMethods$.next(methods);
          subscription.unsubscribe();
        })
      )
    }
}
