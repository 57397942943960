import { NgModule } from '@angular/core';
import { checkoutTranslationChunksConfig, checkoutTranslations } from "@spartacus/checkout/base/assets";
import { CheckoutAuthGuard } from '@spartacus/checkout/base/components';
import { CheckoutRootModule, CHECKOUT_FEATURE } from "@spartacus/checkout/base/root";
import { CmsConfig, I18nConfig, provideConfig } from "@spartacus/core";
import { provideOutlet } from '@spartacus/storefront';
import { CustomItemListComponent } from '../cart/components/custom-item-list/custom-item-list.component';
import { CustomCheckoutDeliveryAddressModule } from './custom-checkout-components/custom-checkout-delivery-address/custom-checkout-delivery-address.module';
import { CustomCheckoutDeliveryModeModule } from './custom-checkout-components/custom-checkout-delivery-mode/custom-checkout-delivery-mode.module';
import { CustomCheckoutPaymentMethodModule } from './custom-checkout-components/custom-checkout-payment-method/custom-checkout-payment-method.module';
import { CustomCheckoutReviewOverviewModule } from './custom-checkout-components/custom-checkout-review-overview/custom-checkout-review-overview.module';
import { CustomCheckoutReviewPaymentModule } from './custom-checkout-components/custom-checkout-review-payment/custom-checkout-review-payment.module';
import { CustomCheckoutReviewShippingModule } from './custom-checkout-components/custom-checkout-review-shipping/custom-checkout-review-shipping.module';
import { CustomCheckoutOrderSummaryModule } from './custom-checkout-order-summary/custom-checkout-order-summary.module';
import { CustomCheckoutPlaceOrderModule } from './custom-checkout-place-order/custom-checkout-place-order.module';
import { CustomCheckoutAuthGuard } from './guards/custom-checkout-auth.guard';
import { SnappayModule } from "./snappay/snappay.module";

@NgModule({
  declarations: [],
  imports: [
    CheckoutRootModule,
    CustomCheckoutDeliveryAddressModule,
    CustomCheckoutReviewShippingModule,
    CustomCheckoutPlaceOrderModule,
    CustomCheckoutDeliveryModeModule,
    CustomCheckoutOrderSummaryModule,
    CustomCheckoutReviewOverviewModule,
    CustomCheckoutPaymentMethodModule,
    SnappayModule,
    CustomCheckoutReviewPaymentModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [CHECKOUT_FEATURE]: {
          module: () =>
            import('./checkout-wrapper.module').then((m) => m.CheckoutWrapperModule),
        },
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: checkoutTranslations,
        chunks: checkoutTranslationChunksConfig,
      },
    }),
    { provide: CheckoutAuthGuard, useClass: CustomCheckoutAuthGuard },
    provideOutlet({
      id: 'customCartItemList',
      component: CustomItemListComponent,
    }),
  ],
})
export class CheckoutFeatureModule { }
