import {SnappayOccEndpoints} from "./index";
import {OccConfig} from "@spartacus/core";

const occSnappayEndpoints: SnappayOccEndpoints = {
  paymentCCIframeRequest:
    'users/${userId}/carts/${cartId}/snappay/request-url',
  cardRegistration:
    'users/${userId}/carts/${cartId}/paymentdetails'
}

export const occSnappayConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        ...occSnappayEndpoints,
      },
    },
  },
};
