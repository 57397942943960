import {Injectable} from "@angular/core";
import {CommandService, UserIdService} from "@spartacus/core";
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {ActiveCartFacade, PaymentDetails} from "@spartacus/cart/base/root";
import {filter, switchMap} from "rxjs/operators";
import {SnappayAdapter} from "../adapters/snappay.adapter";
import {ANONYMOUS} from "../../../../../tools/utils";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class SnappayService {
  private cvv = new BehaviorSubject<String>(null);
  cvv$ = this.cvv.asObservable();

  constructor(
    protected spAdapter: SnappayAdapter,
    protected command: CommandService,
    protected httpClient: HttpClient,
    protected userIdService: UserIdService,
    private activeCartService: ActiveCartFacade
  ){}

  protected customCheckoutUrlQuery () {
    return combineLatest([
      this.userIdService.takeUserId(),
      this.activeCartService.getActive()
    ])
      .pipe(switchMap(([ userId, cart ]) => {
        if (userId === ANONYMOUS) {
          return this.spAdapter.getPaymentCCIframe(userId, cart.guid);
        }

        return this.spAdapter.getPaymentCCIframe(userId, cart.code);
      }))
  };

  createCardRegistrationDetails(paymentDetails: PaymentDetails): Observable<PaymentDetails> {
    //return this.createCardRegistrationDetailsCommand.execute({ paymentDetails });
    return this.userIdService.getUserId().pipe(
      switchMap(
        (userId) => this.activeCartService.getActive().pipe(
          filter((cart) => Boolean(cart)),
          switchMap(
            (cart) => {
              const codeCart = userId === ANONYMOUS ? cart.guid : cart.code;
              return this.spAdapter.createCardRegistrationDetails(paymentDetails, userId, codeCart)
            }
          )
        )
      )
    );
  }

  getPaymentCCIframe(): Observable<any> {
    return this.customCheckoutUrlQuery();
  }

  updateCvv(value: String) {
    this.cvv.next(value);
  }
}
