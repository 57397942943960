export const common = {
  common: {
    cancel: 'Cancel',
    delete: 'Delete',
    remove: 'Remove',
    edit: 'Edit',
    restore: 'Restore',
    back: 'Back',
    submit: 'Submit',
    continue: 'Continue',
    save: 'Save',
    done: 'Done',
    home: 'Home',
    noData: 'No data.',
    confirm: 'Confirm',
    more: 'more',
    close: 'Close',
    loading: 'Loading',
    menu: 'Menu',
    reset: 'Reset',
    search: 'Search',
    add: 'Add',
    breadcrumbs: 'breadcrumbs',
    selectFile: 'Select file',
    clear: 'Clear',
    loaded: 'Loaded',
    results: 'Results',
    of: 'of',
  },
  pageMetaResolver: {
    category: {
      title: '{{query}}',
      title_other: '{{query}}',
    },
    checkout: {
      title: 'Checkout',
      cvv: 'Enter Security Code (CVV)'
    },
    search: {
      title: '{{count}} result for "{{query}}"',
      title_other: '{{count}} results for "{{query}}"',
      findProductTitle: '{{count}} result for coupon "{{coupon}}"',
      findProductTitle_other: '{{count}} results for coupon "{{coupon}}"',
      default_title: 'All products',
      heading: 'Results for "{{query}}"',
    },
    product: {
      description: '{{description}}',
      heading: '{{heading}}',
      title: '{{title}}',
    },
  },
  spinner: {
    loading: 'Loading...',
  },
  navigation: {
    categoryNavLabel: 'Category menu',
    footerNavLabel: 'Footer links',
    navigateTo: 'Navigate to {{nav}}',
    scrollToTop: 'Scroll back to the top of the page',
  },
  searchBox: {
    placeholder: 'Search',
    productSearch: 'Find a product',
    ariaLabelInput: 'Search here...',
    ariaLabelSuggestions: 'typing suggestions',
    ariaLabelProducts: 'product results',
    initialDescription:
      'When autocomplete results are available use up and down arrows to review and enter to select. Touch device users, explore by touch or with swipe gestures.',
    suggestionsResult: '{{ count }} suggestion and ',
    suggestionsResult_other: '{{ count }} suggestions and ',
    productsResult: '{{ count }} product is available.',
    productsResult_other: '{{ count }} products are available.',
    resetLabel: 'Reset Search',
    topProducts: 'Top Products',
    help: {
      insufficientChars: 'Please type more characters',
      noMatch: 'We could not find any results',
      exactMatch: '{{ term }}',
      empty: 'Ask us anything',
    },
    sale: 'Sale',
    keywords: 'KEYWORDS',
    partNumber: 'PART NUMBER',
    seeMore: 'See more results ',
  },
  sorting: {
    date: 'Date',
    orderNumber: 'Order Number',
    rma: 'Return Number',
    replenishmentNumber: 'Replenishment Number',
    nextOrderDate: 'Next Order Date',
    pageViewUpdated: 'Page view updated with your selected options.',
  },
  httpHandlers: {
    badRequest: {
      bad_credentials: '{{ errorMessage }}. Please login again.',
      user_is_disabled: '{{ errorMessage }}. Please contact administration.',
      payment_auth: 'Sorry, we are unable to authorize this credit card. Please check the card information or try another card.'
    },
    badGateway: 'A server error occurred. Please try again later.',
    badRequestPleaseLoginAgain: '{{ errorMessage }}. Please login again.',
    badRequestOldPasswordIncorrect: 'Old password incorrect.',
    badRequestGuestDuplicateEmail:
      '{{ errorMessage }} email already exist. Please checkout with a different email to register using a guest account.',
    conflict: 'Already exists.',
    forbidden:
      'You are not authorized to perform this action. Please contact your administrator if you think this is a mistake.',
    gatewayTimeout: 'The server did not respond, please try again later.',
    internalServerError:
      'An Internal Server Error occurred. Please try again later.',
    sessionExpired: 'Your session has expired. Please login again.',
    unknownError: 'An unknown error occurred',
    unauthorized: {
      common: 'An unknown authorization error occured',
      invalid_client: 'Bad client credentials',
    },
    validationErrors: {
      missing: {
        card_cardType:
          'The selected credit card is not supported. Please select another.',
        card_accountNumber: 'The credit card number entered is not valid.',
        card_cvNumber: 'The security code entered is not valid.',
        card_expirationMonth:
          'The credit card expiration date entered is not valid.',
        card_expirationYear:
          'The credit card expiration date entered is not valid.',
        billTo_firstName: 'The first name entered is not valid.',
        billTo_lastName: 'The last name entered is not valid.',
        billTo_street1: 'The address entered is not valid.',
        billTo_street2: 'The address entered is not valid.',
        billTo_city: 'The city entered is not valid for this credit card.',
        billTo_state:
          'The state/province entered is not valid for this credit card.',
        billTo_country:
          'The country/region entered is not valid for this credit card.',
        billTo_postalCode:
          'The zip/postal code is not valid for this credit card.',
        country: {
          isocode: 'Missing country/region',
        },
      },
      invalid: {
        card_expirationMonth:
          'The credit card expiration date entered is not valid.',
        firstName: 'First Name entered is not valid.',
        lastName: 'Last Name entered is not valid.',
        password: 'Password entered is not valid.',
        uid: 'UID is not valid.',
        code: 'Code is not valid.',
        email: 'Email is not valid.',
        region: 'State is not valid.'
      },
    },
    cartNotFound: 'Cart not found.',
    invalidCodeProvided: 'Invalid code provided.',
    voucherExceeded:
      'This coupon has exceeded the number of times it can be used',
    unknownIdentifier: 'Item not found.',
    otherCartErrors: 'Cart errors occurred.',
  },
  miniCart: {
    item: '{{count}} item currently in your cart',
    item_other: '{{count}} items currently in your cart',
    total: '{{total}}',
    count: '{{count}}',
  },
  skipLink: {
    skipTo: 'Skip to',
    labels: {
      header: 'Header',
      main: 'Main Content',
      footer: 'Footer',
      productFacets: 'Product Facets',
      productList: 'Product List',
    },
  },
  carousel: {
    previousSlide: 'Previous slide',
    nextSlide: 'Next slide',
    slideNumber: 'Slide {{currentSlideNumber}}',
    carouselForProduct: 'Carousel, Images for {{product}}',
  },
  formErrors: {
    globalMessage: 'The form you are trying to submit contains errors.',
    required: 'This field is required',
    cxInvalidEmail: 'This is not a valid email format',
    cxInvalidPassword:
      'Password must have at least one uppercase letter, one number, one symbol',
    cxPasswordsMustMatch: 'Password fields must match',
    cxEmailsMustMatch: 'Email fields must match',
    cxStarRatingEmpty: 'Rating field is required',
    cxNoSelectedItemToCancel: 'Select at least one item',
    cxNegativeAmount: 'Amount must be equal or greater than zero',
    cxContainsSpecialCharacters: 'Field cannot contain special characters',
    cxMinAmount: 'Minimum Number of Characters is {{param}}',
    cxMaxAmount: 'Maximum Number of Characters is {{param}}',
    minlength: 'Password must be 8 character minimum',
    pattern: 'The field contains invalid characters',
    maxlength: "The field must contain a maximum of {{requiredLength}} characters",
    maxBlanks: "The field must contain a maximum of {{maxBlanks}} spaces",
    mask: '',
    date: {
      required: 'This field is required',
      min: 'Date cannot be before {{min}}',
      max: 'Date cannot be after {{max}}',
      pattern: 'Use dateformat yyyy-mm-dd',
      invalid: 'Use a valid date',
    },
    file: {
      required: 'File is required',
      empty: 'File should not be empty',
      invalidExtension: 'File extension is not valid',
      tooLarge: 'File size should not exceed {{ maxSize }} MB',
      tooManyEntries: 'The number of items is greater than {{ maxEntries }}',
      notParsable: 'File is not parsable',
      fileNotAllowed: 'This file type is not allowed',
    },
  },
  errorHandlers: {
    scriptFailedToLoad: 'Failed to load the script.',
    refreshThePage: 'Please refresh the page.',
  },
  assistiveMessage: {
    actionCancelled: 'Action cancelled, nothing changed',
  },
  passwordVisibility: {
    showPassword: 'Show password',
    hidePassword: 'Hide password',
  },
  generalErrors: {
    pageFailure: 'The page could not be loaded. Please try again later.',
  },
  chatMessaging: {
    charactersLeft: 'characters left: {{count}}',
    addNewMessage: 'Add New Message',
    send: 'Send',
    uploadFile: 'Upload File',
    informationLabel: '{{author}}. {{text}} at {{date}}',
    messages: 'Messages',
    addMessagePlaceHolder: 'Start Typing...',
    characterLimitAlert: 'Characters limit reached.',
  },
  formValidations: {
    paymentForm: {
      cardNumber: 'Credit card number is a required field',
    }
  }
};

export const translation = {
  generic: {
    ok: `ok`,
  },
  customUpdateProfileForm: {
    title: 'PERSONAL DETAILS',
    save: 'SAVE',
    successMessage: 'Your personal details have been saved',
  },
  customUpdatePassword: {
    title: 'PASSWORD',
    save: 'SAVE',
    successMessage: 'Your pasword has been saved',
  },
  customUpdateEmail: {
    title: 'EMAIL ADDRESS',
    save: 'SAVE',
    successMessage: 'Your email has been updated',
  },
  customAddressForm: {
    company: 'Company',
    postalCode: {
      pattern: 'Please enter a valid 5-digit US postal code to proceed with your order',
      required: 'This field is required',
    },
    line1: {
      pattern: 'We are unable to ship to a PO box. Please provide a physical address for delivery to ensure your order reaches you without delay',
      required: 'This field is required',
    },
    line2: {
      label: 'Address cont.(optional)',
      placeholder: 'Apt/Suite/Floor',
      pattern: 'We are unable to ship to a PO box. Please provide a physical address for delivery to ensure your order reaches you without delay',
      required: 'This field is required',
    },
    contactInfo: 'Contact Info',
    shippingAddress: 'shipping address',
    phoneNumber: { label: 'Phone' },
    addNewDeliveryAddress: 'Shipping Info',
  },
  customLoginForm: {
    register: 'Congratulations, your account has been created',
    createAccount: 'Create Account',
    sign1: 'Sign in or create an account to enjoy',
    sign2: 'FREE standard shipping',
    sign3: 'on all orders.',
    terms1: 'By clicking Sign In, you agree to the current version of our',
    terms2: ', and have read the',
    termOfUse: 'TERMS OF USE',
    terms3: 'Covercraft Privacy Policy.',
    signToCheckout: 'Sign in to checkout',
  },
  customRegistration: {
    consent1:
      'When you create an account, you agree to the current version of our ',
    consent2: ', and have read the',
    privacyPolicy: 'Covercraft Privacy Policy.',
    createAccount: 'Create Account',
  },
  customResetPassword: {
    confirmNewPassword: 'Confirm New Password',
    save: 'save',
  },
  myGarage: {
    modalTitle: 'MY GARAGE',
    noItemsWarn: 'No vehicles in the garage.',
    modalShopBtn: 'SHOP',
    count: '{{count}}',
    item: '{{count}} item currently in your garage',
    item_other: '{{count}} items currently in your garage',
    total: '{{total}}',
  },
  yearMakeModel: {
    altYmmChangeBtn: 'CHANGE',
    altYmmgGarageBtn: 'GARAGE',
    vehicleTitleNeutral: 'PROTECT MY',
    vehicleTitleSucess: 'YOUR VEHICLE',
    forYourVehicle: 'FOR YOUR VEHICLE',
    vehicleTitleFailure: 'NO RESULT FOR',
    vehicleSelectFailure: 'DOESN’T FIT YOUR VEHICLE',
    vehicleSelectNeutral: 'FITS YOUR VEHICLE MORE INFO NEEDED',
    vehicleSelectNeutralComment:
      'In order to be sure you are purchasing the correct product, we need a bit more information.',
    vehicleSetConfirm: 'CONFIRM',
    vehicleSetSave: 'SAVE',
    vehicleSetCancel: 'CANCEL',
    vehicleEnhancements: 'See All Enhancements',
    vehicleSelectConfirmation: 'CONFIRM YOUR VEHICLE',
    vehicleSelectModalConfirmation: 'CONFIRM YOUR PRODUCT FIT',
    vehicleSelectYmmMessage:
      'Don’t know your year, make and model? Click here for tips on how to find it or send us a picture of your vehicle and we can help you identify. ',
    vehicleSelectYmmMessagePart1: 'Don’t know your year, make and model? ',
    vehicleSelectYmmMessagePart2:
      ' for tips on how to find it or send us a picture of your vehicle and we can help you identify.',
    vehicleSelectYmmMessageModal:
      'Please give us your sub-model or if your vehicle has enhanced features.',
    vehicleSelectYmmModalFormMessage:
      'Please enter your vehicles information below. When you confirm, additional information may be displayed.',
    vehicleSelectYmmModalFormOptionsPreMessage:
      'Enhancements will display here once you confirm your vehicle above.',
    vehicleSelectYmmModalVehicleSubModel: 'Vehicle’s Sub-Model',
    vehicleSelectYmmModalSubModel: 'Sub-model',
    vehicleSelectYmmModalSubModelSelect: 'Select your sub-model',
    vehicleSelectYmmModalEnhanced: 'Vehicle’s Enhanced Features',
    vehicleSelectYmmModalSeparator: 'AND',
  },
  ymmCategoryPage: {
    vehicleTitle: 'Seat Covers & More',
  },
  newsletter: {
    latestNews: 'Sign up to get the latest specials and news',
    signup: 'signup',
    send: 'SEND',
  },
  reviewCheckout: {
    reviewItems: 'Review Items',
  },
  shippingMethod: {
    shippingMethod: 'shipping method',
  },
  deliveryMethod: {
    title: 'Delivery Method',
  },
  productDescription: {
    title: 'description',
    badgesTitle: 'best for',
  },
  productKeyFeatures: {
    featuresTitle: 'features',
    featureSpotlight: 'feature spotlight',
    fabricRatings: 'fabric ratings',
    prop65: 'WARNING: California’s Proposition 65',
    prop65Wanting: 'WARNING: ',
    noteMessage: '* Note: ',
    QA: {
      questions: 'STILL HAVE QUESTIONS?',
      subtitle: 'We’re available to answer all your questions.',
      liveChat: 'Live Chat',
      phoneNumber: '1-800-274-7006',
      time: 'M-F, 9am-7pm (EST)',
    },
  },
  productInstallation: {
    resources: 'resources',
    guide: 'Download the Installation Guide',
    talkToUs: {
      title: 'NEED HELP?',
      description:
        'Our trained experts have years of fitment experience and are available to answer questions. ',
    },
  },
  customCheckout: {
    simpleSummary : {
      title: 'SUMMARY',
    },
    summary: {
      title: 'Order Summary',
      subtotal: 'Order Subtotal',
      promoApplied: 'Promo Applied',
      totalDiscounts: 'Discounts Applied',
      deliveryCoast: 'Shipping',
      totalPriceWithTax: 'Total',
      totalTax: 'Tax',
      total: 'Total',
    },
  },
  customOrderHistory: {
    orderDate: 'ORDER DATE',
    orderNumber: 'ORDER NUMBER',
  },
  additionalDetails: {
    title: 'STYLE',
    attributes: {
      fabric: 'Fabric',
      part: 'Part'
    }
  },
  fitmentDetails: {
    title: 'FITMENT DETAILS',
    table: {
      header: {
        year: 'Year',
        make: 'Make',
        model: 'Model',
        subModel: 'SubModel',
        details: 'Vehicle Fitment Details'
      }
    }
  },
  talkToUs: {
    orderReturn: 'Visit Our Help Center'
  },
  checkoutReview: {
    editDeliveryDetails: 'Edit delivery details, opens Delivery Details page'
  }
};
