<ul class="cx-product-summary" *ngIf="product$ | async as product">
  <li>
    <span class="medium-common-font">
      <strong class="extra-bold-common-font" *ngIf="product.customMade">
        {{ 
          'productSummary.customMade' | cxTranslate
        }}
      </strong>
      <span *ngIf="product.leadTime">
        {{ 
          'productSummary.shipWithinBusinessDays' | cxTranslate: {
            days: product.leadTime
          }
        }}
      </span>
    </span>
    <img src="../../../../../assets/static/info-circle.svg" alt="info-icon" title="This is an estimated timeframe which starts after the receipt of your order. Actual time before shipping may vary based our current production. This estimate does not include shipping transit time, which will be determined by the shipping method selected." />
  </li>

  <li *ngIf="product.calloutMessage">
    <span class="medium-common-font" [innerHTML]="product.calloutMessage" ></span>
  </li>

  <li>
    <div class="divider"></div>
  </li>
</ul>