import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductSummaryComponent } from './product-summary.component';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';

@NgModule({
  declarations: [
    CustomProductSummaryComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductSummaryComponent: {
          component: CustomProductSummaryComponent,
        },
      },
    }),
  ],
})
export class CustomProductSummaryModule { }
