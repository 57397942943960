import { NgModule } from '@angular/core';
import { translationChunksConfig, translations } from "@spartacus/assets";
import { FeaturesConfig, I18nConfig, OccConfig, provideConfig, SiteContextConfig } from "@spartacus/core";
import { CmsPageGuard, defaultCmsContentProviders, layoutConfig, mediaConfig, NavigationService, PageLayoutComponent } from "@spartacus/storefront";
import { AsmConfig } from '@spartacus/asm/root';
import { environment } from 'src/environments/environment';
import { CustomNavigationService } from '../components/_SPA-Components/custom-navigationUI/navigation.service';
import { RouterModule } from "@angular/router";

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        // @ts-ignore
        path: null,
        canActivate: [ CmsPageGuard ],
        component: PageLayoutComponent,
        data: { pageLabel: 'shop', cxRoute: 'ymm' },
      },
    ])
  ],
  providers: [
    provideConfig({
      backend: {
        occ: { baseUrl: environment.baseUrl }
      },
      pwa: { enabled: false }
    } as OccConfig),
    provideConfig(layoutConfig),
    provideConfig(mediaConfig),
    ...defaultCmsContentProviders,
    provideConfig(<SiteContextConfig>{
      context: {
        baseSite: [ 'covercraft' ],
        language: [ 'en' ],
        currency: [ 'USD' ],
      },
    }),
    //provideConfig(<OccConfig>{}),
    provideConfig(<SiteContextConfig>{ context: {}, }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: translations,
        chunks: translationChunksConfig,
        fallbackLang: 'en'
      },
    }),
    provideConfig(<FeaturesConfig>{
      features: {
        level: '6.6'
      }
    }),
    provideConfig({
      checkout: {
        guest: true,
        express: false
      }
    }),
    provideConfig({
      cart: {
        selectiveCart: {
          enabled: true,
        }
      }
    }),
    provideConfig(<AsmConfig>{
      asm: {
        userIdHttpHeader: {
          enable: true,
        },
      },
    }),
    {
      provide: NavigationService,
      useExisting: CustomNavigationService,
    }
  ]
})

export class SpartacusConfigurationModule {
}
