<!-- FORM -->
<p class="cx-checkout-text">
  {{ "paymentForm.choosePaymentMethod" | cxTranslate }}
</p>

<div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
<!-- TODO: (CXSPA-5953) Remove feature flags next major -->
<p *cxFeature="'a11yRequiredAsterisks'" class="form-legend">
  {{ "formLegend.required" | cxTranslate }}
</p>

<ng-container *ngIf="loading">
  <cx-spinner></cx-spinner>
</ng-container>

<form (ngSubmit)="submitForm()" [formGroup]="paymentForm">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="form-group">
        <label>
          <span class="label-content"
          >{{ "paymentForm.accountHolderName.label" | cxTranslate }}
            <ng-template [ngTemplateOutlet]="requiredAsterisk"></ng-template
            ></span>
          <input
            required="true"
            class="form-control"
            type="text"
            placeholder="{{
              'paymentForm.accountHolderName.placeholder' | cxTranslate
            }}"
            formControlName="accountHolderName"
          />
          <cx-form-errors
            [control]="paymentForm.get('accountHolderName')"
          ></cx-form-errors>
        </label>
      </div>
      <div class="form-group">
        <label>
          <span class="label-content">
            {{ 'paymentForm.cardNumber' | cxTranslate }}
          </span>
          <div class="form-control snappay-container">
            <div #iframeContainer class="snappay-iframe"></div>
            <ng-container *ngIf="(isIframeLoading$ | async)">
              <div class="snappay-loading">
                <cx-spinner></cx-spinner>
              </div>
            </ng-container>
          </div>
          <cx-form-errors
            prefix="formValidations"
            [control]="paymentForm.get('cardNumber')"
          ></cx-form-errors>
        </label>
      </div>

      <div class="row align-items-end">
        <div class="form-group col-12">
          <fieldset class="cx-payment-form-exp-date">
            <legend class="label-content">
              {{ "paymentForm.expirationDate" | cxTranslate }}
              <ng-template
                [ngTemplateOutlet]="requiredAsterisk"
              ></ng-template>
            </legend>
            <label class="cx-payment-form-exp-date-wrapper">
              <ng-select
                [inputAttrs]="{ required: 'true' }"
                [searchable]="true"
                [clearable]="false"
                [items]="months"
                placeholder="{{ 'paymentForm.monthMask' | cxTranslate }}"
                formControlName="expiryMonth"
                id="month-select"
                [cxNgSelectA11y]="{
                  ariaLabel:
                    'paymentForm.expirationMonth'
                    | cxTranslate
                      : { selected: paymentForm.get('expiryMonth')?.value }
                }"
              >
              </ng-select>
              <cx-form-errors
                [control]="paymentForm.get('expiryMonth')"
              ></cx-form-errors>
            </label>
            <label class="cx-payment-form-exp-date-wrapper">
              <ng-select
                [inputAttrs]="{ required: 'true' }"
                [searchable]="true"
                [clearable]="false"
                [items]="years"
                placeholder="{{ 'paymentForm.yearMask' | cxTranslate }}"
                id="year-select"
                [cxNgSelectA11y]="{
                  ariaLabel:
                    'paymentForm.expirationYear'
                    | cxTranslate
                      : { selected: paymentForm.get('expiryYear')?.value }
                }"
                formControlName="expiryYear"
              >
              </ng-select>
              <cx-form-errors
                [control]="paymentForm.get('expiryYear')"
              ></cx-form-errors>
            </label>
          </fieldset>
        </div>
      </div>

      <div class="form-group" *ngIf="setAsDefaultField">
        <div class="form-check">
          <label>
            <input
              type="checkbox"
              class="form-check-input"
              (change)="toggleDefaultPaymentMethod()"
            />
            <span class="form-check-label ml-2">{{
              "paymentForm.setAsDefault" | cxTranslate
              }}</span>
          </label>
        </div>
      </div>

      <!-- BILLING -->
      <div class="cx-payment-form-billing">
        <div class="cx-payment-form-billing-address">
          {{ "paymentForm.billingAddress" | cxTranslate }}
        </div>

        <!-- SAME AS SHIPPING CHECKBOX -->
        <ng-container *ngIf="showSameAsDeliveryAddressCheckbox$ | async">
          <div class="form-group">
            <div class="form-check">
              <label>
                <input
                  type="checkbox"
                  class="form-check-input"
                  [checked]="sameAsDeliveryAddress"
                  (change)="toggleSameAsDeliveryAddress()"
                  [attr.aria-label]="
                    'paymentForm.billingAddressSameAsShipping' | cxTranslate
                  "
                />
                <span class="form-check-label ml-2">{{
                  "paymentForm.sameAsDeliveryAddress" | cxTranslate
                  }}</span>
              </label>
            </div>
          </div>
        </ng-container>

        <!-- BILLING INFO COMPONENT -->
        <ng-container
          *ngIf="
            sameAsDeliveryAddress &&
              (deliveryAddress$ | async) as deliveryAddress;
            else billingAddress
          "
        >
          <cx-card
            [content]="getAddressCardContent(deliveryAddress) | async"
          ></cx-card>
        </ng-container>

        <!-- TODO:#future-checkout do we really want this? We can always pass more inputs to the copied address form component to make it more modular -->
        <ng-template #billingAddress>
          <div [formGroup]="billingAddressForm">
            <div class="form-group">
              <label>
                <span class="label-content required">{{
                  "customAddressForm.company" | cxTranslate
                  }}</span>
                <input
                  required="true"
                  class="form-control"
                  type="text"
                  placeholder="{{
                    'customAddressForm.company' | cxTranslate
                  }}"
                  formControlName="companyName"
                />
              </label>
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label>
                  <span class="label-content required">{{
                    "addressForm.firstName.label" | cxTranslate
                    }}</span>
                  <input
                    required="true"
                    class="form-control"
                    type="text"
                    placeholder="{{
                      'addressForm.firstName.placeholder' | cxTranslate
                    }}"
                    formControlName="firstName"
                  />
                  <cx-form-errors
                    [control]="billingAddressForm.get('firstName')"
                  ></cx-form-errors>
                </label>
              </div>
              <div class="form-group col-6">
                <label>
                  <span class="label-content required">{{
                    "addressForm.lastName.label" | cxTranslate
                    }}</span>
                  <input
                    required="true"
                    type="text"
                    class="form-control"
                    placeholder="{{
                      'addressForm.lastName.placeholder' | cxTranslate
                    }}"
                    formControlName="lastName"
                  />
                  <cx-form-errors
                    [control]="billingAddressForm.get('lastName')"
                  ></cx-form-errors>
                </label>
              </div>
            </div>
            <div class="form-group">
              <label>
                <span class="label-content required">{{
                  "addressForm.address1" | cxTranslate
                  }}</span>
                <input
                  required="true"
                  type="text"
                  class="form-control"
                  placeholder="{{
                    'addressForm.streetAddress' | cxTranslate
                  }}"
                  formControlName="line1"
                />
                <cx-form-errors
                  [control]="billingAddressForm.get('line1')"
                ></cx-form-errors>
              </label>
            </div>
            <div class="form-group">
              <label>
                <span class="label-content">{{
                  "customAddressForm.line2.label" | cxTranslate
                  }}</span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{
                    'customAddressForm.line2.placeholder' | cxTranslate
                  }}"
                  formControlName="line2"
                />
              </label>
            </div>
            <div class="form-group" formGroupName="country">
              <ng-container *ngIf="countries$ | async as countries">
                <div *ngIf="countries.length !== 0">
                  <label>
                    <span class="label-content required">{{
                      'addressForm.country' | cxTranslate
                      }}</span>
                    <ng-select
                      [inputAttrs]="{ required: 'true' }"
                      [searchable]="true"
                      [clearable]="false"
                      [items]="countries"
                      bindLabel="name"
                      bindValue="isocode"
                      placeholder="{{
                        'addressForm.selectOne' | cxTranslate
                      }}"
                      (change)="countrySelected($event)"
                      formControlName="isocode"
                      id="country-payment-select"
                      [cxNgSelectA11y]="{
                        ariaLabel: 'addressForm.country' | cxTranslate
                      }"
                    >
                    </ng-select>
                    <cx-form-errors
                      [control]="billingAddressForm.get('country.isocode')"
                    ></cx-form-errors>
                  </label>
                </div>
              </ng-container>
            </div>
            <div class="row">
              <div class="form-group col-4">
                <label>
                  <span class="label-content required">{{
                    "addressForm.city.label" | cxTranslate
                    }}</span>
                  <input
                    required="true"
                    type="text"
                    class="form-control"
                    placeholder="{{
                      'addressForm.city.placeholder' | cxTranslate
                    }}"
                    formControlName="town"
                  />
                  <cx-form-errors
                    [control]="billingAddressForm.get('town')"
                  ></cx-form-errors>
                </label>
              </div>
              <ng-container
                *ngIf="regions$ | async as regions"
                formGroupName="region"
              >
                <ng-container *ngIf="regions.length !== 0">
                  <div class="form-group col-4">
                    <label>
                      <span class="label-content required">{{
                        "addressForm.state" | cxTranslate
                        }}</span>
                      <ng-select
                        [inputAttrs]="{ required: 'true' }"
                        class="region-select"
                        formControlName="isocode"
                        [searchable]="true"
                        [clearable]="false"
                        [items]="regions"
                        bindLabel="{{
                          regions[0].name ? 'name' : 'isocode'
                        }}"
                        bindValue="{{
                          regions[0].name ? 'isocode' : 'region'
                        }}"
                        placeholder="{{
                          'addressForm.selectOne' | cxTranslate
                        }}"
                        id="region-select"
                        [cxNgSelectA11y]="{
                          ariaLabel: 'addressForm.state' | cxTranslate
                        }"
                      >
                      </ng-select>
                      <cx-form-errors
                        [control]="
                          billingAddressForm.get('region.isocode')
                        "
                      ></cx-form-errors>
                    </label>
                  </div>
                </ng-container>
              </ng-container>
              <div class="form-group col-4">
                <label>
                  <span class="label-content required">{{
                    "addressForm.zipCode.label" | cxTranslate
                    }}</span>
                  <input
                    required="true"
                    type="text"
                    class="form-control"
                    placeholder="{{
                      'addressForm.zipCode.placeholder' | cxTranslate
                    }}"
                    formControlName="postalCode"
                  />
                  <cx-form-errors
                    [control]="billingAddressForm.get('postalCode')"
                  ></cx-form-errors>
                </label>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <!-- BUTTON SECTION -->
  <div class="cx-checkout-btns row">
    <div class="col-md-12 col-lg-6 my-1">
      <button
        *ngIf="paymentMethodsCount === 0"
        class="btn btn-block btn-optional-1 btn-back"
        (click)="back()"
      >
        {{ "common.back" | cxTranslate }}
      </button>
      <button
        *ngIf="paymentMethodsCount > 0"
        class="btn btn-block btn-optional-1 btn-back"
        (click)="close()"
      >
        {{ "common.back" | cxTranslate }}
      </button>
    </div>
    <div class="col-md-12 col-lg-6 my-1" *ngIf="{ value: isIframeLoading$ | async } as isLoading">
      <button
        [disabled]="isLoading.value"
        class="btn btn-block btn-secondary btn-add-new-payment"
        type="submit"
      >
        {{ "paymentForm.addNewPayment" | cxTranslate }}
      </button>
    </div>
  </div>
</form>

<ng-template #requiredAsterisk>
  <abbr
    *cxFeature="'a11yRequiredAsterisks'"
    class="text-decoration-none"
    title="{{ 'common.required' | cxTranslate }}"
  >*</abbr
  >
</ng-template>
