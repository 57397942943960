import { Inject, Injectable, Optional } from '@angular/core';
import { Product } from '@spartacus/core';
import { CurrentProductService, JSONLD_PRODUCT_BUILDER, JsonLdBuilder, ProductSchemaBuilder } from '@spartacus/storefront';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

   @Injectable({
     providedIn: 'root',
   })
   export class CustomProductSchemaBuilder extends ProductSchemaBuilder {
     private productService: CurrentProductService;

     constructor(
      currentProduct: CurrentProductService,
      @Optional()
      @Inject(JSONLD_PRODUCT_BUILDER)
      protected override builders: JsonLdBuilder<Product>[],
    ) {
        super(currentProduct, builders);
        this.productService = currentProduct; 
     }

     override build(): Observable<any> {  
      return this.productService.getProduct().pipe(
        switchMap((product: Product | null) => {   
          if (product) {
            return combineLatest(this.collect(product)).pipe(
              map((res: {}[]) => Object.assign({}, ...res))
            );
          }
          return of({});
        })
      );
    }

    override collect(product: Product): Observable<any>[] {
      if (!product || !product.code) {
        return [];
      }
    
      const builders = this.builders
        ? this.builders.map((builder) => builder.build(product))
        : [];
    
      let customJsonLd = of({});
      if (!product.reviews?.length) {        
        customJsonLd = of({
          '@context': 'http://schema.org',
          '@type': 'Product',
          'aggregateRating': {
            '@type': 'AggregateRating',
            'ratingValue': 0,
            'reviewCount': 0 
          },
        });
      }
    
      return [
        of({
          '@context': 'http://schema.org',
          '@type': 'Product',
        }),
        customJsonLd,
        ...builders,
      ];
    }    
   }
