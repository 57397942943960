import { ChangeDetectionStrategy, Component, Inject, Renderer2 } from "@angular/core";
import { CurrentProductService } from "@spartacus/storefront";
import { map, take } from "rxjs/operators";
import { CommonModule, DOCUMENT } from "@angular/common";
import { CustomProductSchemaBuilder } from "../custom-product-schema-builder";
import { WindowRef } from "@spartacus/core";

@Component({
  selector: 'cx-product-overview',
  templateUrl: './product-overview.component.html',
  standalone: true,
  imports: [
    CommonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductOverviewComponent {
  overview$ = this.currentProductService.getProduct().pipe(map(product => product?.summary));
  
  constructor(
    private currentProductService: CurrentProductService,
    private customProductSchemaBuilder: CustomProductSchemaBuilder,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private winRef: WindowRef
  ) { }

  ngOnInit(): void {
    this.customProductSchemaBuilder.build().pipe(take(1)).subscribe((schema) => {  
      this.updateProductJsonLd(schema);

      if (this.winRef.isBrowser()) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.addedNodes.length) {
              mutation.addedNodes.forEach((node) => {
                if (node instanceof HTMLScriptElement && node.type === 'application/ld+json') {              
                  if (node.id === 'custom-product-schema') {          
                    return;
                  }
                  try {
                    const content = JSON.parse(node.textContent || '{}');
                    if (content['@type'] === 'Product') {     
                      node.remove();
                    }
                  } catch (e) {
                    console.error('JSON parsing error:', e);
                  }
                }
              });
            }
          });
        });
    
        observer.observe(this._document.head, { childList: true, subtree: true });
      }
    });
  }
  
  private updateProductJsonLd(schema: any): void {
    const script = this.renderer.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(schema);
    script.id = 'custom-product-schema';
   
    this.renderer.appendChild(this._document.head, script);
  }  
}
