import {NgModule} from "@angular/core";
import {SnappayAdapter} from "./adapters/snappay.adapter";
import {OccSnappayAdapter} from "./adapters/occ-snappay.adapter";
import {CCIframeRequestNormalizer} from "./adapters/occ-snappay-cc-iframe.normalizer";
import {CC_IFRAME_REQUEST_NORMALIZER} from "./adapters/converters";
import {SnappayService} from "./facade/snappay.service";
import {provideConfig } from "@spartacus/core";
import {occSnappayConfig} from "./adapters/config/occ-snappay-endpoint.config";

@NgModule({
  providers: [
    {
      provide: SnappayAdapter,
      useClass: OccSnappayAdapter
    },
    {
      provide: CC_IFRAME_REQUEST_NORMALIZER,
      useExisting: CCIframeRequestNormalizer,
      multi: true,
    },
    SnappayService,
    provideConfig(occSnappayConfig)
  ]
})

export class SnappayModule {}
