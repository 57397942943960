import { Component, OnInit } from '@angular/core';
import { Product, WindowRef } from '@spartacus/core';
import { CurrentProductService, ProductSummaryComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { SkuPageService } from '../../sku-landing-page/skupage.service';
import { CustomSeoMetaService } from 'src/app/core/cms/seo/custom-seo-meta-service';

@Component({
  selector: 'cx-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.scss'],
})
export class CustomProductSummaryComponent
  extends ProductSummaryComponent
  implements OnInit
{
  ngOnInit(): void {
    this.checkMeta();
  }

  constructor(
    protected override currentProductService: CurrentProductService,
    protected skuPageService: SkuPageService,
    protected customSeoMetaService: CustomSeoMetaService,
    private winRef: WindowRef
  ) {
    super(currentProductService);
  }

  override product$: Observable<Product | null> =
    this.currentProductService.getProduct();

  checkMeta() {
    if (this.skuPageService.isSKUPage()) {
      this.customSeoMetaService.setCanonicalUrl(this.winRef.location.href);
    }
  }
}
