import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCheckoutPaymentMethodComponent } from './custom-checkout-payment-method.component';
import { RouterModule } from '@angular/router';
import { CartNotEmptyGuard, CheckoutAuthGuard } from '@spartacus/checkout/base/components';
import { CardModule, SpinnerModule } from '@spartacus/storefront';
import { CmsConfig, FeaturesConfigModule, I18nModule, provideConfig } from '@spartacus/core';
import {
  CustomCheckoutPaymentMethodFormModule
} from "./custom-checkout-payment-method-form/custom-checkout-payment-method-form.module";



@NgModule({
  declarations: [
    CustomCheckoutPaymentMethodComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CustomCheckoutPaymentMethodFormModule,
    CardModule,
    SpinnerModule,
    I18nModule,
    FeaturesConfigModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPaymentDetails: {
          component: CustomCheckoutPaymentMethodComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
  exports: [CustomCheckoutPaymentMethodComponent],
})

export class CustomCheckoutPaymentMethodModule { }
