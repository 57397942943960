import { Injectable } from '@angular/core';
import { Converter } from '@spartacus/core';
import {CustomCCIframeQuery, IframeCCPaymentDetails} from '../models';

@Injectable({
  providedIn: 'root',
})

export class CCIframeRequestNormalizer implements Converter<IframeCCPaymentDetails, CustomCCIframeQuery> {
  convert( source: IframeCCPaymentDetails, target: CustomCCIframeQuery ): CustomCCIframeQuery {
    if (target === undefined) {
      target = { ...(source as any) };
    }

    target.userId = source?.parameters?.entry?.find((it: any) => it.key === 'user_id')?.value;
    target.cartId = source?.parameters?.entry?.find((it: any) => it.key === 'cart_id')?.value;
    return target;
  }
}
