import { Observable } from 'rxjs';
import { PaymentDetails} from "@spartacus/cart/base/root";

export abstract class SnappayAdapter {
  abstract getPaymentCCIframe(
    userId?: string,
    cartId?: string
  ): Observable<string>;

  abstract createCardRegistrationDetails(
    paymentDetails: PaymentDetails,
    userId?: string,
    cartId?: string
  ): Observable<PaymentDetails>;

  abstract createPaymentDetails(
    sessionId: string,
    signature: string,
    userId?: string,
    cartId?: string
  ): Observable<PaymentDetails>;
}
