<h2 class="cx-checkout-title d-none d-lg-block d-xl-block">
  {{ 'paymentForm.payment' | cxTranslate }}
</h2>

<ng-container *ngIf="cards$ | async as cards">
  <ng-container *ngIf="!(isUpdating$ | async); else loading">
    <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
    <ng-container *ngIf=" cards?.length && !newPaymentFormManuallyOpened; then hasExistingPaymentMethods; else newPaymentForm ">
    </ng-container>
  </ng-container>

  <ng-template #hasExistingPaymentMethods>
    <p class="cx-checkout-text">
      {{ 'paymentForm.choosePaymentMethod' | cxTranslate }}
    </p>

    <ng-container *cxFeatureLevel="'!6.3'">
      <div class="cx-checkout-btns row">
        <div class="col-md-12 col-lg-6">
          <button class="btn btn-block btn-secondary" (click)="showNewPaymentForm()">
            {{ 'paymentForm.addNewPayment' | cxTranslate }}
          </button>
        </div>
      </div>
    </ng-container>

    <div class="cx-checkout-body row">
      <div class="cx-payment-card col-12 col-sm-6 col-md-6 col-lg-6" *ngFor="let card of cards; let i = index">
        <div class="cx-payment-card-inner" (click)="selectPaymentMethod(card.paymentMethod)">
          <cx-card [border]="true" [fitToContainer]="true" [content]="card.content" [index]="i" (sendCard)="selectPaymentMethod(card.paymentMethod)"></cx-card>
        </div>
      </div>
    </div>

    <div class="cx-checkout-btns-bottom cx-checkout-btns">
      <div class="row">
        <div class="col-xs-12 col-lg-4">
          <button class="btn btn-block btn-optional-1" (click)="back()">
            {{ backBtnText | cxTranslate }}
          </button>
        </div>

        <div class="col-xs-12 col-lg-4">
          <ng-container *cxFeatureLevel="'6.3'">
            <button class="btn btn-block btn-secondary" (click)="showNewPaymentForm()">
              {{ 'paymentForm.addNewPayment' | cxTranslate }}
            </button>
          </ng-container>
        </div>

        <div class="col-xs-12 col-lg-4">
          <button class="btn btn-block btn-primary" [disabled]="!(selectedMethod$ | async)?.id" (click)="next()">
            {{ 'common.continue' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #newPaymentForm>
    <cx-payment-form
      (setPaymentDetails)="setPaymentDetails($event)"
      (closeForm)="hideNewPaymentForm()"
      (goBack)="back()"
      [paymentMethodsCount]="cards?.length || 0"
      [setAsDefaultField]="!isGuestCheckout && !!cards?.length"
      [loading]="isUpdating$ | async"
      [paymentDetails]="paymentDetails"
    ></cx-payment-form>
  </ng-template>
</ng-container>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
