import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,

  Input,

  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, User, WindowRef } from '@spartacus/core';
import { HamburgerMenuService, NavigationUIComponent } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'cx-navigation-ui',
  templateUrl: './custom-navigation-ui.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomNavigationUIComponent extends NavigationUIComponent {
  customNode: any;
  selectedButton: any;
  focusedButton: any;
  selectedLink: any;
  isMobileMenuHidden: boolean = false;
  savedMobileSiblings: HTMLElement[] = [];
  user$?: Observable<User | undefined>;
  userName?: string;
  lastRootNodeOpen: string;

  @Input() iconDisabled: boolean = false;

  constructor(
    router: Router,
    renderer: Renderer2,
    elemRef: ElementRef,
    protected override hamburgerMenuService: HamburgerMenuService,
    protected override winRef: WindowRef,
    private auth: AuthService,
    private userAccount: UserAccountFacade
  ) {
    super(router, renderer, elemRef, hamburgerMenuService, winRef);
    if (this.node !== undefined) {
      this.handleNavNodes();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.lastRootNodeOpen = null; 
      this.handleNavNodes();
    }
  }

  override ngOnInit() {
    if (this.resetMenuOnClose) {
      this.resetOnMenuCollapse();
    }
        this.user$ = this.auth.isUserLoggedIn().pipe(
          switchMap((isUserLoggedIn) => {
            if (isUserLoggedIn) {
              return this.userAccount.get();
            } else {
              return of(undefined);
            }
          })
        );
      this.user$.subscribe((user) => {
        if (user?.name) {
          this.userName = user.name
        }
      });          
      }



  handleNavNodes() {
    const nivel0: any = this.node?.children;
    const result = this.processNode(nivel0, 1);
    this.customNode = result;
  }

  processNode(items: any[], level: number): any[] {
    const result: any[] = [];
    if (Array.isArray(items)) {
      items.forEach((item) => {
        const newItem = {
          ...item,
          level,
        };

        if (item.children && item.children.length > 0) {
          newItem.children = this.processNode(item.children, level + 1);
        }

        result.push(newItem);
      });
      result.map((item, index) => {
        const newItem = {
          ...item,
          hasLevel4: this.hasLevel4Child(item),
        };
        result[index] = newItem;
      });
    }
    return result;
  }

  hasLevel4Child(item: any): boolean {
    if (item.level === 4) {
      return true;
    }
    if (item.children && item.children.length > 0) {
      return item.children.some((child: any) => this.hasLevel4Child(child));
    }
    return false;
  }

  handleSelectedLink(child: any) {
    this.selectedLink = child;
  }

  toggleLevel4(jsClass: string, event: Event, child: any) {
    this.hideAllLevel4Elements();
    this.selectedButton = child;

    const clickedElement = event.currentTarget as HTMLElement;
    const parentElement = clickedElement.parentElement?.parentElement;

    if (parentElement) {
      const children = parentElement.getElementsByClassName(jsClass);
      const childrenArray = Array.from(children);

      childrenArray.forEach((childElement) => {
        if (
          childElement !== clickedElement &&
          childElement instanceof HTMLElement
        ) {
          childElement.style.display =
            childElement.style.display === 'none' ? 'block' : 'none';
        }
      });

      const siblings = Array.from(clickedElement.parentElement?.children || []);
      this.savedMobileSiblings = siblings as HTMLElement[];

      if (!this.isMobileMenuHidden) {
        siblings.forEach((sibling) => {
          if (sibling === clickedElement) {
            sibling.classList.remove('not-selected-level4-button');
          } else {
            sibling.classList.add('not-selected-level4-button');
          }
        });
        this.isMobileMenuHidden = true;
      } else {
        siblings.forEach((sibling) => {
          sibling.classList.remove('not-selected-level4-button');
        });
        this.isMobileMenuHidden = false;
      }
    }
  }

  toggleCustomStandartItem(event: Event) {
    const clickedElement = event.currentTarget as HTMLElement;
    const parentElement = clickedElement.parentElement?.parentElement?.children;

    if (parentElement) {
      const childrenArray = Array.from(parentElement);

      childrenArray.forEach((child) => {
        if (child.classList.contains('custom-mobile-wrapper')) {
          if (!child.classList.contains('hide-mobile-wrapper')) {
            child.classList.add('hide-mobile-wrapper');
          } else {
            child.classList.remove('hide-mobile-wrapper');
          }
        }
      });
    }
  }

  toggleLevel4Item(event: Event) {
    const clickedElement = event.currentTarget as HTMLElement;
    const parentElement = clickedElement.parentElement?.children;

    if (parentElement) {
      const childrenArray = Array.from(parentElement);

      childrenArray.forEach((child) => {
        if (child.classList.contains('custom-mobile-wrapper')) {
          if (!child.classList.contains('hide-mobile-wrapper')) {
            child.classList.add('hide-mobile-wrapper');
          } else {
            child.classList.remove('hide-mobile-wrapper');
          }
        }
      });
    }
  }

  hideAllLevel4Elements() {
    const level4Elements = document.querySelectorAll('.level4-toggle');
    level4Elements.forEach((element) => {
      if (element instanceof HTMLElement) {
        element.style.display = 'none';
      }
    });
  }

  getCssStyle(title: string): string {
    return (
      'level4-toggle level4-custom-child js-' +
      title?.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase()
    );
  }

  setBackgroundOnFocus(child: any) {
    this.focusedButton = child;
  }

  customBack() {
    if (!this.isMobileMenuHidden) {
      this.back();
    } else {
      this.savedMobileSiblings.forEach((sibling) => {
        sibling.classList.remove('not-selected-level4-button');
        sibling.classList.remove('selected-level4-button');

        this.isMobileMenuHidden = false;
      });
    }
  }

  toggleOpenNew(event: UIEvent, nodeTitle: string): void {
   
    this.toggleOpen(event);
    
    let element: HTMLElement = document.getElementById('level-4-menu-item-' + nodeTitle) as HTMLElement;
    element.click();
  }

  onMouseEnterNew(event: MouseEvent, nodeTitle: string): void {

    this.onMouseEnter(event);
    if (this.lastRootNodeOpen != nodeTitle ){
      let element: HTMLElement = document.getElementById('level-4-menu-item-' + nodeTitle ) as HTMLElement;
      element.click();
      this.lastRootNodeOpen = nodeTitle;
    }
  }

  trimTitle(title: string): string{

    return title.replaceAll(" ", "");
  }

}
