import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CheckoutPlaceOrderComponent } from '@spartacus/checkout/base/components';
import { GlobalMessageService, GlobalMessageType, RoutingService, WindowRef } from '@spartacus/core';
import { OrderFacade } from '@spartacus/order/root';
import { LAUNCH_CALLER, LaunchDialogService, PageLayoutService } from '@spartacus/storefront';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { ContentsData, MetaPixel } from '../../meta-pixel/meta-pixel';
import { map, take } from 'rxjs/operators';
import {SnappayService} from "../snappay/facade/snappay.service";

@Component({
  selector: 'custom-place-order',
  templateUrl: './custom-checkout-place-order.component.html',
  styleUrls: [ './custom-checkout-place-order.component.scss' ]
})

export class CustomCheckoutPlaceOrderComponent extends CheckoutPlaceOrderComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  isDisabled$ = new BehaviorSubject(true);
  cvv: String = '';

  forbiddenPages = [
    "CheckoutDeliveryAddress",
    "CheckoutDeliveryMode",
    "CheckoutPaymentDetails",
    "CheckoutReviewOrder"
  ];

  constructor(
    protected override orderFacade: OrderFacade,
    protected override routingService: RoutingService,
    protected override fb: UntypedFormBuilder,
    protected override launchDialogService: LaunchDialogService,
    protected override vcr: ViewContainerRef,
    private pageLayoutService: PageLayoutService,
    private globalMessageService: GlobalMessageService,
    protected winRef: WindowRef,
    private metaPixel: MetaPixel,
    protected snappayService: SnappayService,
  ) {
    super(orderFacade, routingService, fb, launchDialogService, vcr);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.pageLayoutService.page$.subscribe(page => {
        const isDisabled = this.forbiddenPages.includes(page.pageId);
        this.isDisabled$.next(isDisabled);
      })
    );
    this.subscriptions.add(
      this.snappayService.cvv$.subscribe(value => {
        this.cvv = value;
        if (value != '' && value != null) {
          this.isDisabled$.next(false);
        } else {
          this.isDisabled$.next(true);
        }
      })
    );
  }

  override ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
  }

  override submitForm(): void {
    if (this.cvv) {
      this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
      this.placedOrder = this.launchDialogService.launch(
        LAUNCH_CALLER.PLACE_ORDER_SPINNER,
        this.vcr
      );
      this.checkoutSubmitForm.get(`termsAndConditions`).setValue(true);
      this.subscriptions.add(
        this.orderFacade.placeOrder(this.checkoutSubmitForm.valid)
          .subscribe({
            error: (error: any) => {
              this.handlePaymentError(error);
              if (!this.placedOrder) {
                return;
              }
              this.placedOrder
                .subscribe((component) => {
                  this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
                  if (component) {
                    component.destroy();
                  }
                })
                .unsubscribe();
            },
            next: () => {
              this.onSuccess();
            },
          })
      );
    }
  }

  private handlePaymentError(error: any): void {
    if (error?.status === 400) {
      const exception = error.details?.[ 0 ];
      if (exception?.type === 'PaymentAuthorizationError') {
        this.globalMessageService.add(
          { key: 'httpHandlers.badRequest.payment_auth' },
          GlobalMessageType.MSG_TYPE_ERROR,
          15000
        );
      }
    }
  }


  override onSuccess(): void {
    this.subscriptions.add(
      this.metaPixel.eventPurchase()
        .pipe(take(1))
        .subscribe(()=>{
          this.routingService.go({ cxRoute: 'orderConfirmation' });
      })
    );
  }
}
