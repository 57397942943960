import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import { CheckoutPaymentFormComponent } from '@spartacus/checkout/base/components';
import {
  CheckoutDeliveryAddressFacade,
  CheckoutPaymentFacade,
} from '@spartacus/checkout/base/root';
import {
  Address,
  GlobalMessageService,
  TranslationService,
  UserAddressService,
  UserPaymentService,
} from '@spartacus/core';
import {
  AddressFormComponent,
  LaunchDialogService,
} from '@spartacus/storefront';
import {BehaviorSubject, Subscription} from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { SnappayService } from "../../../snappay/facade/snappay.service";
import {PaymentDetails} from "@spartacus/cart/base/root";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'cx-payment-form',
  templateUrl: './custom-checkout-payment-method-form.component.html',
  styleUrls: ['./custom-checkout-payment-method-form.component.scss'],
})
export class CustomCheckoutPaymentMethodFormComponent
  extends CheckoutPaymentFormComponent
  implements OnInit, OnDestroy {
  @ViewChild(AddressFormComponent) addressFormComponent: AddressFormComponent;
  @ViewChild('iframeContainer', { static: false }) snappayContainer: ElementRef<HTMLElement>;

  private subscriptions = new Subscription;
  private address: Address;
  isIframeLoading$ = new BehaviorSubject<boolean>(true);
  token = '';
  cardType = {
    code: ''
  }
  override paymentForm: UntypedFormGroup = this.fb.group({
    cardType: this.fb.group({
      code: [null, Validators.required],
    }),
    accountHolderName: ['', Validators.required],
    cardNumber: ['', this.validatCardNumber],
    expiryMonth: [null, Validators.required],
    expiryYear: [null, Validators.required],
    defaultPayment: [false],
  });

  override billingAddressForm: UntypedFormGroup = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    line1: ['', Validators.required],
    line2: [''],
    town: ['', Validators.required],
    region: this.fb.group({
      isocode: [null, Validators.required],
    }),
    country: this.fb.group({
      isocode: [null, Validators.required],
    }),
    postalCode: ['', Validators.required],
  });

  constructor(
    protected override checkoutPaymentFacade: CheckoutPaymentFacade,
    protected override checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    protected override userPaymentService: UserPaymentService,
    protected override globalMessageService: GlobalMessageService,
    protected override fb: UntypedFormBuilder,
    protected override userAddressService: UserAddressService,
    protected override launchDialogService: LaunchDialogService,
    protected override translationService: TranslationService,
    protected snappayService: SnappayService,
    protected cdr: ChangeDetectorRef,
    protected sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private readonly document: any
  ) {
    super(
      checkoutPaymentFacade,
      checkoutDeliveryAddressFacade,
      userPaymentService,
      globalMessageService,
      fb,
      userAddressService,
      launchDialogService,
      translationService
    );
  }

  override ngOnInit(): void {
    this.selectedCountry$.next('US');
    super.ngOnInit();
    this.loadIframe();
  }

  loadIframe(): void {
    this.subscriptions.add(
      this.snappayService.getPaymentCCIframe()
        .subscribe({
          next: (request) => {
            this.setIframeUrl(request);
          },
          error: (err) => {
            console.error('Erro ao carregar a URL do iframe:', err);
          }
        })
    );

    this.subscriptions.add(
      this.checkoutDeliveryAddressFacade
      .getDeliveryAddressState()
      .pipe(
        filter((state) => Boolean(state?.data)),
        map((state) => state.data)
      )
      .subscribe((address) => {
        this.address = address;
      })
    );
  }

  private setIframeUrl(url: string){
    const iframe: HTMLIFrameElement = this.document.createElement('iframe');
    iframe.id = 'idSnapPayIframe';
    iframe.src = url;
    iframe.style.display = 'none';
    iframe.className = 'snappay-iframe';
    this.snappayContainer.nativeElement.appendChild(iframe);
    iframe.onload = () => {
      iframe.style.display = '';
      this.isIframeLoading$.next(false);
    }
  }

  submitForm(): void {
    const paymentDetails = this.paymentForm.value;

    if (this.paymentForm.valid) {

      const paymentDetails: PaymentDetails = {
        accountHolderName: this.paymentForm.value.accountHolderName,
        expiryMonth: this.paymentForm.value.expiryMonth as string,
        issueNumber: this.paymentForm.value.issueNumber,
        expiryYear: this.paymentForm.value.expiryYear as string,
        defaultPayment: this.paymentForm.value.defaultPayment,
        saved: true,
        subscriptionId: this.token,
        cardNumber: this.token,
        cardType: this.cardType,
        billingAddress: this.address
      };

      if (this.sameAsDeliveryAddress) {
        this.setPaymentDetails.emit(paymentDetails);
      } else {
        if (this.billingAddressForm.valid) {
          paymentDetails.billingAddress = this.billingAddressForm.value;
          this.setPaymentDetails.emit(paymentDetails);
        } else {
          this.billingAddressForm.markAllAsTouched();
        }
      }
    } else {
      this.paymentForm.markAllAsTouched();

      if (!this.sameAsDeliveryAddress) {
        this.billingAddressForm.markAllAsTouched();
      }
    }
  }

  private handleMessage(event: MessageEvent) {
    if (event.origin.includes('snappay')) {
      var pairs = event.data.split('&');
      var result: any[] = [];
      pairs.forEach(function (pair: any) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
      });
      // @ts-ignore
      let token = result['token'];
      // @ts-ignore
      let cardType = result?.['brand'] ? result['brand'].toLowerCase() : '';

      if (token) {
        this.token = token;
        this.cardType = { code: cardType};
        this.paymentForm.patchValue({
          cardNumber: token,
          cardType: { code: cardType }
        })
      } else {
        this.paymentForm.get('cardNumber').markAsTouched();
      }
    }
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    this.handleMessage(event);
  }

  private validatCardNumber(control: AbstractControl): ValidationErrors | null {
    const original = control.value;
    if (!original) {
      return { 'paymentForm.cardNumber': true };
    }
    return null;
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.handleMessage.bind(this));
  }
}
