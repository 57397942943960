import { ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import {
  CmsComponentData,
  CurrentProductService,
  PageLayoutService,
  PageTitleComponent,
  ProductListComponentService
} from '@spartacus/storefront';
import { BreadcrumbMeta, CmsBreadcrumbsComponent, CmsService, isNotNullable, Page, PageMetaService, TranslationService, WindowRef } from '@spartacus/core';
import { filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { combineLatest, EMPTY, Observable, Subscription } from "rxjs";
import { GarageService } from "../../../spartacus/features/ymm/core/facade/garage.service";
import { OrderDetailsService } from '@spartacus/order/components';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'cx-breadcrumb',
  templateUrl: './custom-breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomBreadcrumbComponent
  extends PageTitleComponent
  implements OnInit
{
  breadcrumbSubscription = new Subscription();

  forbiddenPageTitle = [
    'SearchResultsListPageTemplate',
    'YMMCategoryPageTemplate',
    'ProductListPageTemplate',
    'ProductDetailsPageTemplate',
  ];

  // breadCrumbPageLayoutMap: {
  //   [key: string]: () => Observable<BreadcrumbMeta[]>;
  // } = {
  //   all: this.allResolver,
  //   YMMCategoryPageTemplate: this.ymmResolver,
  //   ProductDetailsPageTemplate: this.pdpResolver,
  // };

  getBreadCrumb(page: Page): Observable<BreadcrumbMeta[]> {
    if (page?.template === `YMMCategoryPageTemplate`) {
      return this.ymmResolver();
    }

    if (page?.template === `ProductDetailsPageTemplate`) {
      return this.pdpResolver();
    }

    if (page?.template === `AccountPageTemplate` && page?.pageId === `order`) {
      return this.orderPageResolver();
    }

    return this.allResolver();
  }

  contentWithTitle$ = combineLatest([
    this.pageMetaService.getMeta(),
    this.pageLayoutService.page$,
  ]).pipe(map(([meta, pageLayout]: any) => ({ meta, pageLayout })));

  customTitle$ = combineLatest([
    this.contentWithTitle$,
    this.cmsService.getCurrentPage(),
    this.garageService.getActiveVehicle(),
  ]).pipe(
    map(([contentWithTitle, page, activeVehicle]: any) => {
      if (this.forbiddenPageTitle.includes(page?.template)) {
        return undefined;
      }

      return (
        contentWithTitle.meta?.heading ||
        contentWithTitle.meta?.title ||
        contentWithTitle.pageLayout.name ||
        ''
      );
    })
  );

  private homeCrumb$ = this.translation.translate('common.home').pipe(
    map((textHome) => ({ label: textHome, link: '/' })),
    shareReplay(1)
  );

  // crumbs$ = this.cmsService.getCurrentPage().pipe(
  //   switchMap((page) => {
  //     const resolver =
  //       this.breadCrumbPageLayoutMap[page.template] ??
  //       this.breadCrumbPageLayoutMap['all'];
  //     return resolver.call(this);
  //   })
  // );

  crumbs$ = this.cmsService.getCurrentPage().pipe(
    switchMap((page) => {
      return this.getBreadCrumb(page);
    })
  );

  allLayoutPage$ = combineLatest([
    this.pageMetaService.getMeta().pipe(
      startWith(undefined),
    ),
    this.translation.translate('common.home'),
    this.cmsService.getCurrentPage()
  ]).pipe(
    map(([meta, textHome, page]) =>
      {
        if(page?.typeCode == 'CategoryPage' && page.template == 'LandingPage2Template'){
          return [{label: textHome, link: '/'}, {label: page?.title ?? page?.description}]
        }

        return meta?.breadcrumbs ? meta.breadcrumbs : [{ label: textHome, link: '/' }]}
    )
  );

  eyebrow$ = combineLatest([
    this.pageMetaService.getMeta(),
    this.cmsService.getCurrentPage(),
  ]).pipe(
    map(([meta, page]) => {
      if (page?.template && this.forbiddenPageTitle.includes(page.template)) {
        return undefined;
      }

      return meta.eyebrow;
    })
  );

  constructor(
    component: CmsComponentData<CmsBreadcrumbsComponent>,
    pageMetaService: PageMetaService,
    private translation: TranslationService,
    private cmsService: CmsService,
    private garageService: GarageService,
    private currentProductService: CurrentProductService,
    private pageLayoutService: PageLayoutService,
    private orderService: OrderDetailsService,
    private renderer: Renderer2,
    private productListComponentService: ProductListComponentService,
    private winRef: WindowRef,
    @Inject(DOCUMENT) private _document: Document
  ) {
    super(component, pageMetaService);
  }

  override ngOnInit(): void {
    this._setTitle();
    this.breadcrumbSubscription = this.crumbs$.subscribe((breadCrumbMeta) => {
      if (breadCrumbMeta?.length) {
        this.setJsonLdScript(breadCrumbMeta);
      }
    })
  }

  _setTitle(): void {
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(isNotNullable),
      map((meta) => (meta.heading || meta.title) ?? '')
    );
  }

  private allResolver(): Observable<BreadcrumbMeta[]> {
    return this.allLayoutPage$;
  }

  private ymmResolver(): Observable<BreadcrumbMeta[]> {
    const activeVehicle = this.garageService.getActiveVehicle();
    if (!activeVehicle) {
      return EMPTY;
    }
    return combineLatest([
      this.homeCrumb$,
      this.garageService.getActiveVehicle(),
      this.cmsService.getCurrentPage(),
      this.productListComponentService.model$
    ]).pipe(
      map(([home, activeVehicle, p, productModel]) => {
        if (!activeVehicle) {
          return [home, { label: '' }];
        }

        let breadcrumbs: any[] = [home];
        const yearFromPage = p.year;
        const { year, make, model } = activeVehicle.ymm;
        if (productModel.currentQuery.url.includes('levelOneCategories')) {
          let link = '';
          // YMM/MM category page
          if (!yearFromPage && p.label === 'shop') {
            // MM page
            link = '/shop/' + `${p.make}-${p.model}`;
            breadcrumbs.push({ label: `${p.make} ${p.model}`, link: link })
          } else {
            // YMM page
            link = '/shop/' + `${year}-${make}-${model}`;
            breadcrumbs.push({ label: `${year} ${make} ${model}`, link: link })
          }
          let cats = productModel.currentQuery.url.substring(productModel.currentQuery.url.indexOf('levelOneCategories:'))
            .split('levelOneCategories:')
            .filter(item => item && item.trim() !== '');
          for (let i=0; i<cats.length; i++) {
            const label = cats[i].replaceAll('-', ' ')
              .replaceAll(':', '')
              .replace(/(^|\s)[a-z]/gi, (l: string) => l.toUpperCase());
            if (i == cats.length - 1) {
              breadcrumbs.push({ label: label});
            } else {
              link += '/' + cats[i].replaceAll(':', '');
              breadcrumbs.push({ label: label, link: link});
            }
          }
          return breadcrumbs;
        } else {
          // YMM/MM page
          if (!yearFromPage && p.label === 'shop') {
            // Currently on MM page - display Make and Model from page
            return [home, { label: `${p.make} ${p.model}` }];
          }
          return [home, { label: `${year} ${make} ${model}` }];
        }
      })
    );
  }

  private pdpResolver(): Observable<BreadcrumbMeta[]> {
    return combineLatest([
      this.translation.translate('common.home'),
      this.cmsService.getCurrentPage(),
      this.currentProductService.getProduct(),
    ]).pipe(
      map(([textHome, page, product]) => {
        let breadcrumb = [{ label: textHome, link: '/' }];

        if (product?.categoryBreadcrumb?.length) {
          product?.categoryBreadcrumb.forEach((breadcrumbItem) => {
            breadcrumb.push({
              label: breadcrumbItem.name,
              link: breadcrumbItem.url,
            });
          });

          if (page.template === 'ProductDetailsPageTemplate') {
            breadcrumb.push({ label: product.name, link: '' });
          }
        }

        return breadcrumb;
      })
    );
  }

  private orderPageResolver(): Observable<BreadcrumbMeta[]> {
    return combineLatest([
      this.homeCrumb$,
      this.orderService.getOrderDetails(),
    ]).pipe(
      map(([home, order]) => {
        if (!order) {
          return [home, { label: '' }];
        }
        return [home, { label: order.code }];
      })
    );
  }

  private setJsonLdScript(breadCrumbMeta: BreadcrumbMeta[]) {
    let breadcrumbs: Array<any> = [];

    breadcrumbs = breadCrumbMeta.map((breadCrumb, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        name: breadCrumb.label || 'home',
        item: breadCrumb.link || breadCrumbMeta[index + 1] ? `${this.winRef.location.origin}${breadCrumb.link}`: `${this.winRef.location.href}`,
      };
    });

    const breadcrumbsJsonLd = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      'itemListElement': breadcrumbs,
    };

    const script = this.renderer.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(breadcrumbsJsonLd);
    this.renderer.appendChild(this._document.head, script);
  }

  ngOnDestroy() {
    this.breadcrumbSubscription.unsubscribe();
  }
}

